import axios from "axios";
import { envName } from "../configs/index";

export let BASE_URL = "";
if (process.env.NODE_ENV === "production") {
  BASE_URL = process.env.PROD_API_2;
}
if (process.env.NODE_ENV === "development") {
  BASE_URL = process.env.DEV_API_2;
}

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

let isNetworkError = false;

axiosInstance.interceptors.request.use(
  async (config) => {
    if (isNetworkError) {
      return Promise.reject(new Error("Сеть недоступна"));
    }
    let accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken && !refreshToken) {
      localStorage.removeItem(`${envName}-uuid`);
      window.location.href = "/login";
      return;
    }
    try {
      const accessTokenResponse = await axios.post(
        `${BASE_URL}/auth/verify-token`,
        { token: accessToken }
      );

      if (!accessTokenResponse.data) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/refresh-access-token`,
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );
          accessToken = response.data.accessToken;
          localStorage.setItem("accessToken", accessToken);
        } catch (err) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login";
          return Promise.reject(err);
        }
      }
      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers["if-unmodified-since"] = new Date(
          Date.now()
        ).toISOString();

        return config;
      }
      config.headers["if-unmodified-since"] = new Date(
        Date.now()
      ).toISOString();

      return config;
    } catch (error) {
      console.log(error);
      // window.location.href = "/service-unavailable";
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    isNetworkError = false; // Сеть восстановлена, сбрасываем флаг
    return response.data;
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      isNetworkError = true;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
